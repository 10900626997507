<template>
  <v-dialog v-model="showDialog" persistent fullscreen>
    <v-card :loading="loading" :disabled="loading">
      <v-toolbar flat tile dark>
        <v-btn @click="cancel" x-large icon>
          <v-icon>close</v-icon>
        </v-btn>

        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="errorCode === null"
                   style="text-align: center; margin-top: 200px; font-weight: bold; font-size: 30px;">
        <p>{{ $t("generic.lang_pleaseHoldTheRfidChipToTheReader") }}</p>
        <v-progress-circular indeterminate color="primary" size="50" width="3"
                             style="margin-top: 20px;"></v-progress-circular>
      </v-card-text>

      <v-card-text v-else style="text-align: center; margin-top: 200px;">
        <v-row>
          <v-col cols="12" md="6" offset-md="3">
            <div v-if="errorCode === 'not_found'">
              <v-alert color="error" dark style="font-size: 23px; line-height: 30px">
                {{ $t("generic.lang_braceletWithUidNotFound") }}
              </v-alert>

              <v-btn v-if="$store.getters['permissions/checkPermission']('allow_activateZeroRfidTag')" color="success"
                     @click="activateAsZero(false)" :loading="loadingActivate" :disabled="loadingActivate">
                     0€ {{ $t("generic.lang_activateBracelet") }}
              </v-btn>

              <v-divider></v-divider>
            </div>

            <div v-if="errorCode === 'not_active'">
              <v-alert color="warning" dark style="font-size: 23px; line-height: 30px">
                {{ $t("generic.lang_braceletHasNotYetBeenActivated") }}
              </v-alert>

              <p><b>{{ $t("generic.lang_positions") }}: </b>
                <span v-for="position in preOrder.items" :key="position.randomPositionID">
                  <br>{{ position.amount }}x {{ position.name }}
                </span>
              </p>

              <v-btn v-if="$store.getters['permissions/checkPermission']('allow_activateRfidTag')" color="success"
                     :disabled="loadingActivate" :loading="loadingActivate" @click="activateUID">{{ $t("generic.lang_activateBracelet") }}
              </v-btn>
              <v-btn v-if="$store.getters['permissions/checkPermission']('allow_activateZeroRfidTag')" color="success"
                     @click="activateAsZero(true)" :loading="loadingActivate" :disabled="loadingActivate">
                     0€ {{ $t("generic.lang_activateBracelet") }}
              </v-btn>

              <v-divider></v-divider>
            </div>

            <v-alert v-if="errorCode === 'old_new_same'" color="primary" dark
                     style="font-size: 23px; line-height: 30px">
              {{ $t("generic.lang_pleaseScanNewBracelet") }}
            </v-alert>

            <v-alert v-if="errorCode === 'old_not_found'" color="error" dark style="font-size: 23px; line-height: 30px">
              {{ $t("generic.lang_oldBraceletWasNotFound") }}
            </v-alert>

            <v-alert v-if="errorCode === 'new_exists'" color="error" dark style="font-size: 23px; line-height: 30px">
              {{ $t("generic.lang_newBraceletCannotBeUsedThereAreAlreadyBookings") }}
            </v-alert>

            <v-btn color="primary" @click="closeErrorDialog">{{ $t("generic.lang_scanAgain") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {mapState} from "vuex";
import {Events} from "@/plugins/events";

export default {
  name: "RFIDScan",

  data() {
    return {
      showDialog: true,
      loading: false,
      loadingActivate: false,

      barcodeCodeString: "",
      barcodeClearID: "",
      rfidUID: "",
      errorCode: null,
      preOrder: null
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    cardTitle() {
      if (this.$route.query.hasOwnProperty("replaceUID")) {
        if (this.$route.query.replaceUID) {
          return "Armband wechseln";
        }
      }

      return "Armband scannen";
    }
  },

  mounted() {
    // PAUSE BARCODE SCANNER
    this.$root.$emit("barcodeScanner.pause");

    window.addEventListener("keypress", this.addToBarcode);
    this.barcodeKeyInterval();
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.addToBarcode);
    clearInterval(this.barcodeClearID);

    //UNPAUSE BARCODE SCANNER
    this.$root.$emit("barcodeScanner.unpause");
  },

  methods: {
    addToBarcode(e) {
      //CHECK IF WE SHOULD SKIP INPUTS
      if (this.pause)
        return;

      if (e.which === 13) {
        e.preventDefault();
        this.returnBarcode();
      } else {
        this.barcodeCodeString += String.fromCharCode(e.keyCode);

        //RESTART TIMER
        clearInterval(this.barcodeClearID);
        this.barcodeKeyInterval();
      }
    },
    clearBarcodeString() {
      this.barcodeCodeString = "";
    },
    barcodeKeyInterval() {
      this.barcodeClearID = setInterval(() => {
        this.clearBarcodeString()
      }, 700);
    },

    async returnBarcode() {
      if (this.errorCode !== null)
        return;

      let rfidUID = this.barcodeCodeString;
      this.rfidUID = rfidUID;

      this.loading = true;

      // CHECK IF WANT TO REPLACE UID
      if (this.$route.query.hasOwnProperty("replaceUID")) {
        if (this.$route.query.replaceUID) {
          // CHANGE RFIDs
          this.axios.post(ENDPOINTS.POS.GASTRO.CHANGERFIDTABLE, {
            oldUID: this.pos.gastro.table.name,
            newUID: rfidUID
          }).then((res) => {
            if (res.data.success) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_braceletHasBeenChanged"),
                color: "success"
              });

              this.$router.replace({
                name: 'pos.rfid'
              });
              return;
            } else {
              this.errorCode = res.data.code;
            }
          }).catch(() => {

          }).finally(() => {
            this.clearBarcodeString();
            this.loading = false;
          });

          return;
        }
      }

      // FIRST GET ORDER
      let orderRes = await this.axios.post(ENDPOINTS.POS.GASTRO.ORDER.GETBYTABLENAME, {
        tableName: rfidUID
      });

      if (orderRes.data.success) {
        return this.gotoTable(rfidUID);
      }

      // ---- GET PRE ORDER ----
      this.axios.post(ENDPOINTS.POS.GASTRO.PREORDER.GETBYTABLENAME, {
        tableName: rfidUID
      }).then((res) => {
        if (res.data.success) {
          // FOUND PRE-ORDER
          this.errorCode = "not_active";
          this.preOrder = res.data.preorder;
        } else {
          this.errorCode = res.data.code;

          if (res.data.code === "not_found") {

          }
        }
      }).catch(() => {

      }).finally(() => {
        this.clearBarcodeString();
        this.loading = false;
      });
    },
    closeErrorDialog() {
      this.errorCode = null;
    },
    async gotoTable(rfidUID) {
      // GOTO TABLE
      this.$store.commit("pos/gastro/clearTableParties");

      await this.$store.dispatch("pos/gastro/setTable", {
        table: {
          name: rfidUID,
          parties: [
            {
              name: "Partei 1"
            }
          ]
        }
      });

      // GOTO GASTRO POS
      if (this.$vuetify.breakpoint.smAndDown)
        this.$router.replace({
          name: 'pos.gastro',
          query: {
            source: "rfid"
          }
        })
      else this.$router.replace({
        name: 'pos.gastro',
      });
    },

    activateUID() {
      this.loadingActivate = true;

      this.axios.post(ENDPOINTS.POS.GASTRO.ORDER.CREATEFROMPREORDER, {
        tableName: this.rfidUID
      }).then((res) => {
        if (res.data.success) {
          this.gotoTable(this.rfidUID);
        } else {
          this.errorCode = res.data.code;
        }
      }).catch(() => {

      }).finally(() => {
        this.loadingActivate = false;
      });
    },
    activateAsZero(deletePreOrder) {
      this.loadingActivate = true;

      this.axios.post(ENDPOINTS.POS.GASTRO.ORDER.CREATEZERO, {
        tableName: this.rfidUID,
        deletePreOrder: deletePreOrder
      }).then((res) => {
        if (res.data.success) {
          this.gotoTable(this.rfidUID);
        } else {
          this.errorCode = res.data.code;
        }
      }).catch(() => {

      }).finally(() => {
        this.loadingActivate = false;
      });
    },

    cancel() {
      this.$router.replace({
        name: 'pos.gastro.tresenverkauf'
      });
    }
  },
}
</script>
